<template>
  <div class="window" v-if="currentVehicle">
    <div class="window-status">
      <div class="window-status-name">{{ currentVehicle.alarmType }}<span>（{{ currentVehicle.plate }}）</span></div>
      <div class="window-status-item window-status-item1">{{ currentVehicle.level == 1 ? '低' : currentVehicle.level == 2 ? '中' : '高' }}</div>
    </div>
    <div class="window-car">
      <div class="window-cell">
        <div class="window-cell-label">告警时间：</div>
        <div class="window-cell-value">{{ currentVehicle.alarmTime }}</div>
        <div class="window-cell-r" :style="{ color: currentVehicle.handleState == 1 ? '#4CB134' : '' }">{{ currentVehicle.handleState == 1 ? '已处理' : '未处理' }}</div>
      </div>
    </div>
      <div class="window-cell">
        <div class="window-cell-label">车身速度：</div>
        <div class="window-cell-value">{{ currentVehicle.speed }}km/h</div>
      </div>
      <div class="window-cell">
        <div class="window-cell-label" style="min-width:76px">告警地址：</div>
        <div class="window-cell-value text-ellipsis">{{ currentVehicle.address }}</div>
      </div>
    <div class="window-go">
      <div>管控</div>
      <div>告警详情</div>
    </div>
    <div class="window-closed" @click="$emit('closed')">X</div>
  </div>
</template>

<script>
export default {
  props: {
    currentVehicle: {
      type: Object,
      default: ()=> {}
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.window {
  position: relative;
  width: 424px;
  height: 206px;
  padding-top: 34px;
  padding-left: 24px;
  padding-right: 32px;
  padding-bottom: 10px;
  background-image: url('../../../../assets/images/OperationBigScreen/MapEvents/alarm-win.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &-closed {
    position: absolute;
    top: 8px;
    right: 18px;
    font-weight: bold;
    font-size: 18px;
    color: #52CCFF;
    cursor: pointer;
  }
  &-status {
    display: flex;
    align-items: center;
    gap: 8px;
    &-item {
      height: 21px;
      line-height: 21px;
      font-size: 12px;
      color: #fff;
      padding: 0 10px;
      border-radius: 10px;
    }
    &-item0 {
      background: #585858;
    }
    &-item1 {
      background: #F9823A;
    }
    &-item2 {
      background: #2E98FF;
    }
    &-item3 {
      background: #E72722;
    }
    &-name {
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      color: #F9823A;
      span {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
  &-car {
    margin-top: 10px;
    display: flex;
    &-img {
      width: 56px;
      height: 56px;
      margin-right: 13px;
    }
    &-centent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-name {
        font-weight: bold;
        font-size: 18px;
        color: #B2D9FF;
      }
      &-type {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
      &-g {
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
  &-go {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 400;
    font-size: 14px;
    color: #52CCFF;
    margin-top: 18px;
    >div {
      cursor: pointer;
    }
  }
  &-cell {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 12px;
    &-label {
      color: rgba(255, 255, 255, .5);
    }
    &-r {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 500;
      font-size: 14px;
      color: #E72722;
    }
  }
}
</style>