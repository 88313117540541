<template>
  <div ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header bg1">
      <div class="path-left">
        <img
          src="../../../assets/images/bigScreen/equipment/goHome.png"
          @click="handleGoPath('/SCProjectHall')"
          style="width: 0.3rem"
          class="go-home"
          alt=""
          srcset=""
        />
        <img @click="handleGoPath()"
          src="@/assets/images/OperationBigScreen/DeploymentControl/back.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          alt=""
          srcset=""
        />
      </div>
      <!-- <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.1em">{{ weatherText }}</span>
      </div> -->
      <span style="flex: 1"></span>
      <div class="header-title p-clip">{{ projectObj.lsProjectName }}监控</div>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>

      <div class="path-right">
        <img @click="SetFullscreen"
          src="@/assets/images/OperationBigScreen/DeploymentControl/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <img src="@/assets/images/OperationBigScreen/MapEvents/top-decoration.png" class="top-decoration" alt="" srcset="">
      <BSJMap
        ref="mapRef"
        :zoom="zoom"
        :center="centerLng"
        mapStyle="amap://styles/30b04e25b1e43697d8e46ca5eb19f169"
        @loaded="onMapLoad"
      >
        <!-- 聚合点 -->
        <MapClusterer
          v-for="(item, index) in clustererList"
          :key="index"
          :points="clusterPoints[item.valueKey]"
          :gridSize="10" :minClusterSize="2"
          pointKey="key"
          :markerOffset="markerOffset"
          :pointRender="item.pointRender"
          @markerClick="onMarkerClick"
        >
          <template #marker="{ point }">
            <!-- <Iconfont
              :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
              :size="32"
              :rotate="point.data.F || 0"
            ></Iconfont>-->
            <!-- <Iconfont
              :size="56"
              :height="61"
              :name="vehicleIconName(point.data, item.valueKey)"
            ></Iconfont> -->
            <div style="width:56px;height:61px;position:relative;display:flex;align-items:center;justify-content:center;">
              <img :src="vehicleIconName(point.data, item.valueKey)" style="width:56px;height:61px;" alt="" srcset="">
              <img :src="sbdxIconName(point.data, item.valueKey)" class="sbdx" style="width:50px;height:19px;position:absolute;left:3px;bottom:3px;z-index:0;" alt="" srcset="">
            </div>
          </template>
        </MapClusterer>
        <!-- 展示地图信息窗体 -->
        <InfoWindow
          ref="windowObj"
          v-model="infoVisible"
          :lngLat="windowPoint"
          :offset="[0, -20]"
          :width="424"
          :height="0"
          autoMove
        >
          <Window
            :currentVehicle="currentVehicle"
            @closed="(infoVisible = false), (currentVehicle = null)"
          />
        </InfoWindow>
      </BSJMap>
      <div class="arrangement">
        <div class="arrangement-content">
          <div class="arrangement-content-top">
            <div @click="handleGoPath()"
              class="arrangement-content-top-item arrangement-content-top-item1"
            >
              布控地图
            </div>
            <div class="arrangement-content-top-item">事件地图</div>
            <img src="../../../assets/images/OperationBigScreen/DeploymentControl/zs-l.png" class="arrangement-content-top-icon" alt="" srcset="">
          </div>
          <div class="arrangement-content-control">
            <img @click="centerLng = JSON.parse(JSON.stringify(idPositioning))"
              src="../../../assets/images/OperationBigScreen/DeploymentControl/positioning-icon.png"
              alt=""
              srcset=""
            />
            <img
              src="../../../assets/images/OperationBigScreen/DeploymentControl/enlarged-icon.png"
              alt=""
              @click="zoom < 18 ? (zoom += 1) : ''"
              srcset=""
            />
            <img
              src="../../../assets/images/OperationBigScreen/DeploymentControl/narrow-icon.png"
              @click="zoom > 3 ? (zoom -= 1) : ''"
              alt=""
              srcset=""
            />
          </div>
          <div class="arrangement-content-statistics">
            <div
              class="arrangement-content-statistics-item arrangement-content-statistics-item1"
            >
              {{ vehicleList.alarmCount }}
            </div>
            <div class="arrangement-content-statistics-item">{{ vehicleList.vehicleCount }}</div>
          </div>
          <div class="arrangement-content-display">
            <div class="arrangement-content-display-tabs">
              <div
                class="arrangement-content-display-tabs-item"
                :class="{
                  'arrangement-content-display-tabs-item-c': tabsIndex == 1,
                }"
                @click="tabsIndex = 1,getEventMapStatistics()"
              >
                今日
              </div>
              <div
                class="arrangement-content-display-tabs-item"
                :class="{
                  'arrangement-content-display-tabs-item-c': tabsIndex == 2,
                }"
                @click="tabsIndex = 2,getEventMapStatistics()"
              >
                本周
              </div>
              <div
                class="arrangement-content-display-tabs-item"
                :class="{
                  'arrangement-content-display-tabs-item-c': tabsIndex == 3,
                }"
                @click="tabsIndex = 3,getEventMapStatistics()"
              >
                本月
              </div>
              <div
                class="arrangement-content-display-tabs-decoration"
                :style="{
                  left: `calc(100% / 3 * ${tabsIndex} - 100% / 3 / 2)`,
                }"
              ></div>
            </div>
            <div
              class="arrangement-content-display-item"
              v-for="(item, index) in contentList"
              :key="index"
            >
              <img
                class="arrangement-content-display-item-icon"
                :src="alarmTypeList[item.dataType].icon"
                alt=""
                srcset=""
              />
              <div class="arrangement-content-display-item-label">
                {{ alarmTypeList[item.dataType].label }}
              </div>
              <div class="arrangement-content-display-item-value">
                {{ leftOption[alarmTypeList[item.dataType].valueKey] || 0 }}
              </div>
            </div>
            <div class="arrangement-content-display-num">
              <div class="arrangement-content-display-num-item">
                <div class="arrangement-content-display-num-item-top">
                  未处理
                </div>
                <div class="arrangement-content-display-num-item-v">{{ leftOption.notHandleCount || 0 }}</div>
              </div>
              <div class="arrangement-content-display-num-item">
                <div class="arrangement-content-display-num-item-top">
                  已处理
                </div>
                <div class="arrangement-content-display-num-item-v2">{{ leftOption.handleCount || 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { weatherInfo, amapGetIp } from "@/api/weather";
import { getWeek, getTimes } from "@/utils/getDate";
import dayjs from "dayjs";
import { mapMutations } from "vuex";
import BSJMap, { InfoWindow, MapClusterer } from "@/components/AMap";
import Window from "@/views/OperationBigScreen/MapEvents/components/Window";
import GPS from "@/utils/address";
import { projectLSContentList, eventMap, eventMapStatistics } from "@/api/riskData.js";

export default {
  // `name`与路由保持一致
  name: "SCMapEvents",
  components: { BSJMap, InfoWindow, MapClusterer, Window },
  data() {
    // this.intervalId = null;
    return {
      timeStr: "", // 时间
      timer: null, // 时间定时器
      loading: false, //加载
      weatherText: "", //天气说明
      weatherImg: "", //天气图标
      weatherArea: "", //天气地址
      vehicleTotal: 0,
      fullscreenchange: null,
      visibilitychange: null,
      backLoading: false,
      intervalId: null,
      dayNum: "",
      dateText: "",
      centerLng: null,
      zoom: 10,
      markerOffset: [0, 0],
      infoVisible: false, //
      currentVehicle: null,
      tabsIndex: 1,
      alarmTypeList: {
        10: {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon5.png"),
          label: "超速报警次数",
          valueKey: "csalarmCount",
        },
        11: {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon2.png"),
          label: "AEB制动报警次数",
          valueKey: "aebAlarmCount",
        },
        12: {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon1.png"),
          label: "前向碰撞预警次数",
          valueKey: "aebFcwAlarmCount",
        },
        13: {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon4.png"),
          label: "车距检测预警次数",
          valueKey: "hmwAlarmCount",
        },
        14: {
          icon: require("../../../assets/images/OperationBigScreen/MapEvents/display-icon3.png"),
          label: "车道偏离预警次数",
          valueKey: "aebLdwAlarmCount",
        },
      },
      alarmDataList: {
        qxpzyj: [],
        zdbj: [],
        cdpl: [],
        cjjc: [],
        csbj: [],
      },
      clustererList: [],
        // {
        //   dataType: 12,
        //   valueKey: 'qxpzyj',
        //   pointRender: (e) => { this.handleSetPointRender(e, 'qxpzyj') }
        // },
        // {
        //   dataType: 11,
        //   valueKey: 'zdbj',
        //   pointRender: (e) => { this.handleSetPointRender(e, 'zdbj') }
        // },
        // {
        //   dataType: 14,
        //   valueKey: 'cdpl',
        //   pointRender: (e) => { this.handleSetPointRender(e, 'cdpl') }
        // },
        // {
        //   dataType: 13,
        //   valueKey: 'cjjc',
        //   pointRender: (e) => { this.handleSetPointRender(e, 'cjjc') }
        // },
        // {
        //   dataType: 10,
        //   valueKey: 'csbj',
        //   pointRender: (e) => { this.handleSetPointRender(e, 'csbj') }
        // },
      alarmNameList: [
        {
          dataType: 10,
          valueKey: 'csbj',
          pointRender: (e) => { this.handleSetPointRender(e, 'csbj') }
        },
        {
          dataType: 11,
          valueKey: 'zdbj',
          pointRender: (e) => { this.handleSetPointRender(e, 'zdbj') }
        },
        {
          dataType: 12,
          valueKey: 'qxpzyj',
          pointRender: (e) => { this.handleSetPointRender(e, 'qxpzyj') }
        },
        {
          dataType: 14,
          valueKey: 'cdpl',
          pointRender: (e) => { this.handleSetPointRender(e, 'cdpl'); }
        },
        {
          dataType: 13,
          valueKey: 'cjjc',
          pointRender: (e) => { this.handleSetPointRender(e, 'cjjc') }
        },
      ],
      alarmNameObj: {
        10: 'csbj',
        11: 'zdbj',
        12: 'qxpzyj',
        13: 'cjjc',
        14: 'cdpl',
      },
      projectObj: {},
      leftOption: {},
      vehicleList: {},
      cententLoading: false,
      leftLoading: false,
      contentList: [
        {
          dataType: 10,
          duration: null,
          isDisplayed: true,
          sort: 1
        },
        {
          dataType: 11,
          duration: null,
          isDisplayed: true,
          sort: 2
        },
        {
          dataType: 12,
          duration: null,
          isDisplayed: true,
          sort: 3
        },
        {
          dataType: 13,
          duration: null,
          isDisplayed: true,
          sort: 4
        },
        {
          dataType: 14,
          duration: null,
          isDisplayed: true,
          sort: 5
        },
      ],
      idPositioning: []
    };
  },
  created() {},
  // mounted
  activated() {
    let that = this;
    if (this.$route.query.project) {
      this.projectObj = JSON.parse(this.$route.query.project);
    }
    this.init();
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = dayjs().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = dayjs(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },
  computed: {
    // 聚合点
    clusterPoints() {
      const { alarmDataList } = this;
      for (const key in alarmDataList) {
        alarmDataList[key] = alarmDataList[key].map((obj, indd) => {
          const point = GPS.delta(obj.lat, obj.lon);
          return {
            key: obj.key || indd,
            lng: point.lon,
            lat: point.lat,

            // lng: 116.40121,
            // lat: 39.907795,

            data: obj,
          };
        })
      };
      return alarmDataList;
    },
    // 聚合点
    clusterPoints2() {
      const { vehicleList } = this;
      return vehicleList.map((obj, indd) => {
        const point = GPS.delta(obj.Y, obj.X);
        return {
          key: obj.key || indd,
          lng: point.lon + 0.02,
          lat: point.lat,
          data: obj,
        };
      });
    },
    windowPoint() {
      // this.infoVisible = true;
      // return [116.40121, 39.907795];
      const { currentVehicle } = this;
      if (!currentVehicle) {
        this.infoVisible = false;
        return [];
      }
      const point = GPS.delta(currentVehicle.lat, currentVehicle.lon);

      return [point.lon, point.lat];
    },
  },

  methods: {
    async init() {
      this.getModelData();
      let { data, code } = await projectLSContentList({
        lsProjectId: this.projectObj.id,
        type: '2',
      });
      if (code == 200 && data.length) {
        this.contentList = data.filter(val=> val.isDisplayed).sort(this.compare('sort'));
        this.clustererList = this.contentList.map(val=> {
          return {
            dataType: val.dataType,
            valueKey: this.alarmNameObj[val.dataType],
            pointRender: (ee) => { this.handleSetPointRender(ee, this.alarmNameObj[val.dataType]) }
          };
        });
        // 
        // let rightContent = data.filter(vv=> vv.dataType > 3 && vv.dataType < 10).sort(this.compare('sort'));
        // this.contentList = {
        //   leftContent: leftContent.filter(val=> val.isDisplayed),
        //   rightContent: rightContent.filter(val=> val.isDisplayed),
        // };
      } else {
        // this.contentList = [];
      }
    },
    compare(properct){
      return (a, b)=> {
        let value1 = a[properct];
        let value2 = b[properct];
        return value1 - value2;
      }
    },
    getEventMapStatistics() {
      this.leftLoading = true;
      eventMapStatistics({
        lsProjectId: this.projectObj.id,
        dateType: this.tabsIndex == 1 ? 'D' : this.tabsIndex == 2 ? 'W' : 'M'
      }).then(res=> {
        this.leftLoading = false;
        this.leftOption = res.data || {};
      }).catch(err=> {
        this.leftOption = {};
        this.leftLoading = false;
      });
    },
    getModelData() {
      this.getEventMapStatistics();
      this.cententLoading = true;
      eventMap({
        lsProjectId: this.projectObj.id,
      }).then(res=> {
        this.cententLoading = false;
        if (res.data.eventAlarmMapList && res.data.eventAlarmMapList.length) {
          this.alarmDataList.qxpzyj = res.data.eventAlarmMapList.filter(val=> val.alarmTypeId == 403);
          this.alarmDataList.zdbj = res.data.eventAlarmMapList.filter(val=> val.alarmTypeId == 401);
          this.alarmDataList.cdpl = res.data.eventAlarmMapList.filter(val=> val.alarmTypeId == 404);
          this.alarmDataList.cjjc = res.data.eventAlarmMapList.filter(val=> val.alarmTypeId == 405);
          this.alarmDataList.csbj = res.data.eventAlarmMapList.filter(val=> val.alarmTypeId == 399);
          this.centerLng = [res.data.eventAlarmMapList[0].lon, res.data.eventAlarmMapList[0].lat]
        }else {
          this.alarmDataList = {
            qxpzyj: [],
            zdbj: [],
            cdpl: [],
            cjjc: [],
            csbj: []
          };
        }
        this.vehicleList = res.data || [];
      }).catch(err=> {
        this.alarmDataList = {
           qxpzyj: [],
          zdbj: [],
          cdpl: [],
          cjjc: [],
          csbj: []
        };
        this.cententLoading = false;
        console.log(err);
      });
    },
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      // this.intervalId = setTimeout(async () => {
      //   await this.init();
      //   clearTimeout(this.intervalId);
      //   if (this.fullscreenchange) {
      //     this.handlePolling();
      //   }
      // }, 1000 * 60);
    },
    getFullScreenEl() {
      const { el } = this;
      // return (isElement('el')
      //   ? el
      //   : document.querySelector(el)) || document.documentElement;
      return this.$refs["BigScreenDisplay"];
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },

    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      let rectangle = cityData.rectangle.split(";");
      let lan = Number(rectangle[0].split(",")[0]) + Number(rectangle[1].split(",")[0]);
      let lon = Number(rectangle[0].split(",")[1]) + Number(rectangle[1].split(",")[1]);
      this.idPositioning = [lan / 2, lon / 2]
      //  {city: cityData.city}
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },
    handleGoPath(path) {
      if (!path) {
        this.$router.go(-1);
      }
      this.$router.push({
        path,
      });
    },
    onMapLoad(e) {
      console.log(e, "---地图加载完成---");
    },
    onMarkerClick(e) {
      this.infoVisible = true;
      this.currentVehicle = e.data;
      console.log(e, "---点击标点---");
    },
    vehicleIconName(val, current) {
      return require(`../../../assets/images/OperationBigScreen/MapEvents/marker-icon-s-${current}.png`);
    },
    sbdxIconName(val, current) {
      return require(`../../../assets/images/OperationBigScreen/MapEvents/marker-icon-b-${current}.png`);
    },
    handleSetPointRender(context, type, ee) {
      context.marker.setContent(`<div style="width:56px;height:61px;position:relative;display:flex;align-items:center;justify-content:center;"><img src="${require('../../../assets/images/OperationBigScreen/MapEvents/clusterer-sb-'+type+'.png')}" style="width:56px;height:61px;" alt="" srcset=""><img src="${require('../../../assets/images/OperationBigScreen/MapEvents/marker-icon-b-'+type+'.png')}" class="sbdx" style="width:50px;height:19px;position:absolute;left:3px;bottom:3px;z-index:0;" alt="" srcset=""><div style="position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:1;font-weight: 500;font-size: 16px;color: #FFFFFF;">${context.count}</div></div>`);
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
::v-deep .amap-maptypecontrol {
  display: none;
}
::v-deep .amap-controls {
  display: none;
}
::v-deep .WindowContent {
  background-color: transparent;
  border: none;
}
::v-deep .AnchorArrow {
  display: none;
}

/* 定义一个名为fadeIn的动画 */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
::v-deep .sbdx {
  animation: fadeIn 2s ease-in-out infinite;
}
</style>
